import { useContext } from 'react';
import { Box, Grid, TextField, InputLabel } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { CommonDataContext } from '../../context';
import { FieldType } from '../types';
import { Constants } from '../../constants';
import useSocialSecutity from '../../hooks/useSocialSecutity';
import _ from 'lodash';

interface Props {
  itemData: FieldType;
  value: any;
  externalData: any;
  isNeedToMoveLabelOutOfInput: boolean;
  locale: string;
};

export const SocialsecurityField = ({
  itemData,
  value,
  externalData,
  isNeedToMoveLabelOutOfInput,
  locale,
}: Props) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const { isMobileView } = useContext(CommonDataContext);

  const { label, isInvalid, handleChange } = useSocialSecutity(itemData, externalData?.sectionConfig, locale, value);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      {isNeedToMoveLabelOutOfInput && <InputLabel className='outer-label'>{label}</InputLabel>}
      <TextField
        key={fieldId}
        name={fieldConfig.fieldName}
        fullWidth={fieldConfig.fullWidth}
        disabled={fieldConfig.disabled}
        variant={isMobileView ? "outlined" : "standard"}
        error={isInvalid}
        helperText={
          isInvalid && (
            <FormattedMessage
              id={'digitsRequiredSSN'}
              defaultMessage={'digitsRequiredSSN'}
              values={{ digits: Constants.ssnLength - 2 }}
            />
          )
        }
        label={isNeedToMoveLabelOutOfInput ? '' : label}
        required={fieldConfig.required}
        value={value ? value : ''}
        onChange={handleChange}
      />
    </Box>
  );
};
