import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { getURLParameter } from '.';
import { EquipmentDataFromUrlParams, FormattedEquipmentDataFromUrlParams } from '../components/types';
import { Constants } from '../constants';

const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useEffectDebugger = (effectHook: any, dependencies: any, dependencyNames: any) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum: any, dependency: any, index: any) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency
        }
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  useEffect(_.partial(effectHook, changedDeps), dependencies);
};

export default function useWindowDimensions() {
  const getWindowDimensions = () => {
    const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
    return { screenWidth, screenHeight };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const useEquipmentDataFromUrlParams = () => {

  const getEquipmentDataFromUrlParams = (): EquipmentDataFromUrlParams => {

    const getFromUrlParams = (params: string[]) => {
      for (const key of params) {
        const urlParameter = getURLParameter(key);
        if (urlParameter) {
          return urlParameter;
        }
      }
      return undefined;
    };

    return {
      make: getFromUrlParams(['make', 'equipmentMake']),
      model: getFromUrlParams(['model', 'equipmentModel']),
      serialNumberVin: getFromUrlParams(['serialNumber', 'serialNum', 'vin', 'VIN']),
      stockId: getFromUrlParams(['stockId', 'stockNumber', 'SN']),
      retailPrice: getFromUrlParams(['retailPrice', 'price']),
      equipmentPictureUrl: getFromUrlParams(['pictureURL', 'pictureUrl', 'equipmentPictureUrl']),
      newUsed: getFromUrlParams(['condition', 'equipmentCondition']),
      year: getFromUrlParams(['year', 'equipmentYear']),
      hours: getFromUrlParams(['hours', 'equipmentHours']),
      city: getFromUrlParams(['city', 'equipmentCity']),
      postalCode: getFromUrlParams(['postalCode', 'equipmentPostalCode']),
      state: getFromUrlParams(['state', 'equipmentState']),
      quantity: getURLParameter('quantity'),
      mileage: getURLParameter('mileage'),
      tradeInValue: getURLParameter('tradeInValue'),
      amountOwedOnTradeIn: getURLParameter('amountOwedOnTradeIn'),
      additionalDescription: getURLParameter('additionalDescription'),
    };
  };

  const formatEquipmentDataFromUrlParams = (equipmentDataFromUrlParams: EquipmentDataFromUrlParams): FormattedEquipmentDataFromUrlParams => {
    const formatter: any = {
      mileage: (value: string) => +value,
      serialNumberVin: (value: string) => value.slice(0, Constants.equipmentVinLength),
      newUsed: (value: string) => value.toLowerCase(),
    };

    return Object.keys(equipmentDataFromUrlParams).reduce<FormattedEquipmentDataFromUrlParams>((result, key) => {
      const typedKey = key as keyof EquipmentDataFromUrlParams;
      const value = equipmentDataFromUrlParams[typedKey];
      result[typedKey] = value && formatter[typedKey]
        ? formatter[typedKey](value)
        : value;
      return result;
    }, {});
  };

  return {
    getEquipmentDataFromUrlParams,
    formatEquipmentDataFromUrlParams,
  };
}

export {
  usePrevious,
  useEffectDebugger,
  useWindowDimensions,
  useEquipmentDataFromUrlParams,
};
