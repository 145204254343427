import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SectionType } from "../../../types";
import React, { useContext, useEffect, useState } from 'react';
import {
  checkIfIsSignaturesSection,
  hasFieldsErrors
} from '../../../../helpers';
import {
  apiMapping,
} from '../../../../api';

import _ from 'lodash';
import { Formik } from 'formik';
import { CommonDataContext } from '../../../../context';
import { BusinessOwnerInput, Maybe } from '../../../../api/types';
import { OCAv4Section } from '../../../OCAv4Section';

const useStyles = makeStyles(() => {
  return {
    submitButton: {
      '&.MuiButton-root': {
        display: 'block',
        margin: '40px auto 0',
        padding: '8px 40px',
      }
    },
  };
});



type FormikInitialValues = {
  owners?: Maybe<Array<Maybe<BusinessOwnerInput>>>;
  whoIsPresentToSign: number[],
  whoIsPresentToSignForPersonalGuarantee: number[],
};

export const SignatureLayout = ({
  activeTemplate,
  contactToRecertify,
  externalData,
  handleSubmit,
}: {
  activeTemplate: any;
  contactToRecertify: any;
  externalData: any;
  handleSubmit: Function;
}) => {

  const classes = useStyles();
  const { setHighlightErrorFieldsState } = useContext(CommonDataContext);
  const [sectionsToFill, setSectionsToFill] = useState<SectionType[]>([]);
  const [formikInitialValues, setFormikInitialValues] = useState<FormikInitialValues>();
  const OWNER_IDX = 0;

  useEffect(() => {
    if (!activeTemplate || !contactToRecertify) {
      return;
    }
    const allSections = _.flatMap(activeTemplate.jsonDefinition, 'sections');
    const dynamicSignatureAndTitleSection = _.cloneDeep(_.find(allSections, checkIfIsSignaturesSection));

    dynamicSignatureAndTitleSection.config.displayed = true;  // hotfix - for some reason RuleEvaluatorWrapper doesn't set it
    dynamicSignatureAndTitleSection.fields = _.filter(dynamicSignatureAndTitleSection.fields, f => {
      return f.config.fieldName === 'dynamicSignatureAndTitle';
    });
    const sectionsToFill = [dynamicSignatureAndTitleSection];

    const formikInitialValues = {
      owners: [contactToRecertify],
      whoIsPresentToSign: [OWNER_IDX],
      whoIsPresentToSignForPersonalGuarantee: [OWNER_IDX],
    };

    setFormikInitialValues(formikInitialValues);
    setSectionsToFill(sectionsToFill);
  }, [activeTemplate, contactToRecertify]);


  const submit = async (errors: any, values: any) => {
    setHighlightErrorFieldsState({ state: false });
    if (hasFieldsErrors(errors)) {
      setHighlightErrorFieldsState({ state: true });
      return;
    }

    try {
      const mappedValues = apiMapping({
        values,
      });
      const ownerToSave = _.pick(_.first(mappedValues.owners), ['ownerPgId', 'signature', 'signatureDate', 'title']);
      const payload = { recertificationDate: new Date().toISOString(), owners: [ownerToSave] };
      handleSubmit(payload);
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <>

      <Grid container spacing={3}>
        {formikInitialValues &&
          <Grid item xs={12}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              validate={() => { }}
              initialValues={formikInitialValues}
              onSubmit={() => { }}
            >
              {({ errors, values }) =>
                <>
                  {sectionsToFill.map((section: SectionType, index: number) => {
                    return (
                      <OCAv4Section
                        key={section.title.id}
                        locationId={''}
                        vendorContactId={''}
                        setRepData={() => { }}
                        setRepList={() => { }}
                        config={section}
                        template={activeTemplate}
                        externalData={externalData}
                      />
                    );
                  })}
                  <Button
                    className={classes.submitButton}
                    variant="contained"
                    color="primary"
                    onClick={(e: any) => submit(errors, values)}
                  >
                    Submit
                  </Button>
                </>
              }
            </Formik>
          </Grid>
        }
      </Grid>
    </>
  );
};
