import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Alert, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SectionType, Task } from "../../types";
import { useEffect, useRef, useState } from 'react';
import { getURLParameter } from '../../../helpers';
import { TaskStatuses, SectionTypes, BusinessOwnerInput } from '../../../api/types';
import { Formik, FormikProps, FormikValues } from 'formik';
import { OCAv4Section } from '../../OCAv4Section';
import {
  Q_CREDIT_APP,
  UPDATE_CREDIT_APPLICATION_V2_QUERY,
  UPDATE_TASK_STATUS,
  apiMapping,
} from '../../../api';
import { formatCreditAppToFormikFieldTypes } from '../../../helpers/formatCreditAppToFormikFieldTypes';
import _ from 'lodash';


const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      backgroundColor: 'white',
      marginTop: "20px",
      marginBottom: "20px",
    },
  };
});

export const DocumentSignaturePage = ({
  task,
  publicAccount,
  activeTemplate,
}: {
  task: any;
  publicAccount: any;
  activeTemplate: any;
}) => {

  const [
    queryCreditAppById,
    { data: creditAppData },
  ] = useLazyQuery(Q_CREDIT_APP);

  const [updateCreditAppRequest] = useMutation(UPDATE_CREDIT_APPLICATION_V2_QUERY, {
    context: { authRequired: true },
  });

  const [updateTaskStatus] = useMutation(UPDATE_TASK_STATUS, {
    onCompleted() {
      setIsTaskCompleted(true);
    },
    onError() {
      setIsTaskCompleted(false);
    },
  });

  const classes = useStyles();

  const [documentSignSection, setDocumentSignSection] = useState<SectionType>();
  const [uploadedSignedDocument, setUploadedSignedDocument] = useState<any>();
  const [ownerToSign, setOwnerToSign] = useState<BusinessOwnerInput | undefined>();
  const [isTaskCompleted, setIsTaskCompleted] = useState(task.status === TaskStatuses.Complete);

  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const creditAppId = getURLParameter('creditAppId');

  useEffect(() => {
    if (_.isEmpty(activeTemplate) || _.isEmpty(creditAppData)) {
      return;
    }
    const documentSignSection = _(activeTemplate.jsonDefinition)
      .chain()
      .cloneDeep()
      .flatMap('sections')
      .find({ sectionType: SectionTypes.DocumentSign })
      .set(`config.requireAcceptanceToProceed`, true)
      .set(`config.requireSigning`, true)
      .set(`config.displayed`, true)
      .value();
    if (!documentSignSection) {
      return;
    }

    documentSignSection.fields = _.filter(documentSignSection.fields, f => {
      return _.includes(['addendum'], f.config.fieldName);
    });
    const formattedCreditApp = formatCreditAppToFormikFieldTypes(creditAppData.creditApplication, activeTemplate.jsonDefinition);
    const isPrimaryContact = formattedCreditApp.primaryContact.ownerPgId === task.ownerPgId;
    const ownerToSign = isPrimaryContact
      ? formattedCreditApp.primaryContact
      : _.find(formattedCreditApp.owners, { ownerPgId: task.ownerPgId });
    setOwnerToSign(ownerToSign);
    setDocumentSignSection(documentSignSection);
  }, [activeTemplate, creditAppData]);

  useEffect(() => {
    if (!uploadedSignedDocument || !formikRef.current) {
      return;
    }
    submit(formikRef.current?.values);
  }, [uploadedSignedDocument]);

  useEffect(() => {
    if (creditAppId) {
      queryCreditAppById({
        variables: { id: creditAppId },
      });
    }
  }, [creditAppId]);

  const submit = async (values: any) => {
    try {
      const mappedValues = apiMapping({
        values: formikRef.current?.values,
      });
      //const input = _.pick(mappedValues, ['addendum', 'docsUpload']);
      const input = {
        docsUpload: mappedValues.docsUpload,
        owners: [{ ownerPgId: ownerToSign?.ownerPgId, addendum: mappedValues.addendum }]
      }
      const result = await updateCreditAppRequest({ variables: { creditAppId, input } });
      if (_.get(result, 'data.updateCreditApplicationV2')) {
        await updateTaskStatus({
          variables: {
            taskId: task.taskId,
            status: TaskStatuses.Complete
          },
        });
      }
    } catch (error: any) {
      const networkError = _.get(error, 'networkError.result.errors[0].message');
      const message = `Error: ${error.message}. ${networkError ? networkError : ''}.`;
      console.error(message);
    }
  };

  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.containerRoot}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">Document Signature</Typography>
          </Grid>
          {documentSignSection && !isTaskCompleted && <Grid item xs={12}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              validate={() => { }}
              initialValues={{}}
              onSubmit={() => { }}
              innerRef={formikRef}
            >
              {({ errors, values }) =>
                <>
                  <OCAv4Section
                    key={documentSignSection.title.id}
                    locationId={''}
                    vendorContactId={''}
                    setRepData={() => { }}
                    setRepList={() => { }}
                    config={documentSignSection}
                    template={activeTemplate}
                    externalData={{ isTaskPage: true, setUploadedSignedDocument }}
                  />
                </>
              }
            </Formik>
          </Grid>
          }
          {isTaskCompleted && <Grid item xs={12}>
            <Alert severity="success">Document has been successfully signed!</Alert>
          </Grid>
          }
        </Grid>
      </Container>
    </>
  );
};
