import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Alert, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import {
  getURLParameter,
  updateMaskedFieldsConfig,
} from '../../../helpers';
import {
  Q_CREDIT_APP_PUBLIC,
  UPDATE_TASK_STATUS,
  UPDATE_CREDIT_APPLICATION_V2_QUERY,
} from '../../../api';
import { ApplicationType, BusinessOwnerInput, SectionTypes, TaskStatuses } from '../../../api/types';
import { formatCreditAppToFormikFieldTypes } from '../../../helpers/formatCreditAppToFormikFieldTypes';
import { InformationChangedConfirmation } from './layouts/InformationChangedConfirmation';
import { SignatureLayout } from './layouts/SignatureLayout';
import { CreditApplication, UpdateCreditApplicationV2Input } from '@trnsact/trnsact-shared-types';
import { RecertificationOCA } from './layouts/RecertificationOCA';
import _ from 'lodash';
import { FieldConfigType, FieldType, SectionType } from '../../types';

const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      backgroundColor: 'white',
      marginTop: "20px",
      marginBottom: "20px",
    },
  };
});


export const RecertificationSignaturesPage = ({
  task,
  publicAccount,
  activeTemplate,
}: {
  task: any;
  publicAccount: any;
  activeTemplate: any;
}) => {

  const classes = useStyles();
  const [creditApplication, setCreditApplication] = useState<CreditApplication>();
  const [creditApplicationFormatted, setCreditApplicationFormatted] = useState();
  const [isIndividualApp, setIsIndividualApp] = useState(false);
  const [ocaConfig, setOcaConfig] = useState<any>();
  const [isTaskCompleted, setIsTaskCompleted] = useState(task.status === TaskStatuses.Complete);
  const [contactToRecertify, setContactToRecertify] = useState<BusinessOwnerInput>();
  const [hasInformationChanged, setHasInformationChanged] = useState<boolean>();

  const creditAppId = getURLParameter('creditAppId');

  const [
    queryCreditAppById,
    { data: creditAppData },
  ] = useLazyQuery(Q_CREDIT_APP_PUBLIC);

  const [updateCreditAppRequest] = useMutation(UPDATE_CREDIT_APPLICATION_V2_QUERY, {
    context: { authRequired: true },
  });

  const [updateTaskStatus] = useMutation(UPDATE_TASK_STATUS, {
    onCompleted() {
      setIsTaskCompleted(true);
    },
    onError() {
      setIsTaskCompleted(false);
    },
  });

  useEffect(() => {
    if (creditAppId) {
      queryCreditAppById({
        variables: { id: creditAppId },
      });
    }
  }, [creditAppId]);

  useEffect(() => {
    if (!creditAppData || !activeTemplate) {
      return;
    }
    const creditApplication = creditAppData.creditApplicationPublic;
    const isIndividualApp = _.toUpper(_.get(creditApplication, 'applicationType')) === ApplicationType.Individual;
    const ocaConfigCloned = _.cloneDeep(activeTemplate.jsonDefinition) // need to clone to not modify original
    updateMaskedFieldsConfig(ocaConfigCloned);

    const formattedCreditApp = formatCreditAppToFormikFieldTypes(creditApplication, ocaConfigCloned, isIndividualApp);

    const fieldNamesToRemove: string[] = ['locationId', 'vendorContactId', 'existingCustomer', 'bizOrIndividual'];
    _.forEach(_.flatMap(ocaConfigCloned, 'sections'), (section: SectionType) => {
      section.fields = section.fields.filter((field: FieldType) => !fieldNamesToRemove.includes(field.config.fieldName))
    });

    const isPrimaryContact = formattedCreditApp.primaryContact.ownerPgId === task.ownerPgId;
    const contactToRecertify = isPrimaryContact
      ? formattedCreditApp.primaryContact
      : _.find(formattedCreditApp.owners, { ownerPgId: task.ownerPgId });

    setCreditApplication(creditApplication);
    setCreditApplicationFormatted(formattedCreditApp);
    setContactToRecertify(contactToRecertify);
    setIsIndividualApp(isIndividualApp);
    setOcaConfig(ocaConfigCloned);
  }, [creditAppData, activeTemplate]);

  const handleSubmit = async (payload: UpdateCreditApplicationV2Input) => {
    try {
      console.log(payload);
      const result = await updateCreditAppRequest({ variables: { creditAppId: creditApplication?.id, input: payload } });
      if (_.get(result, 'data.updateCreditApplicationV2')) {
        await updateTaskStatus({
          variables: {
            taskId: task.taskId,
            status: TaskStatuses.Complete
          },
        });
      }
    } catch (error: any) {
      const networkError = _.get(error, 'networkError.result.errors[0].message');
      const message = `Error: ${error.message}. ${networkError ? networkError : ''}.`;
      console.error(message);
    }
  };

  const externalData = {
    isIndividualApp,
    isNeedToOmitOcaSpecificHandlingOwnersLogic: true,
  };
  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.containerRoot}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">Recertification Review</Typography>
          </Grid>
          {creditApplication && contactToRecertify && !isTaskCompleted &&
            <Grid item container xs={12}>
              {hasInformationChanged === undefined &&
                <InformationChangedConfirmation
                  task={task}
                  creditApplication={creditApplication}
                  setHasInformationChanged={setHasInformationChanged}
                />
              }

              {hasInformationChanged === false &&
                <SignatureLayout
                  activeTemplate={activeTemplate}
                  contactToRecertify={contactToRecertify}
                  externalData={externalData}
                  handleSubmit={handleSubmit}
                />
              }

              {hasInformationChanged === true &&
                <RecertificationOCA
                  ocaConfig={ocaConfig}
                  publicAccount={publicAccount}
                  activeTemplate={activeTemplate}
                  formikInitialValues={creditApplicationFormatted}
                  externalData={externalData}
                  handleSubmit={handleSubmit}
                />
              }
            </Grid>
          }
          {isTaskCompleted && <Grid item xs={12}>
            <Alert severity="success">Recertification has been successfully completed!</Alert>
          </Grid>
          }
        </Grid>
      </Container>
    </>
  );
};
