import formatString from 'format-string-by-pattern';
import { FormikProps, useFormikContext } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import { FieldType, SectionType, SsnStringPatternsByCountryCodes } from "../components/types";
import { checkIfIsCountryField } from "../helpers";
import { CommonDataContext } from "../context";
import { Constants } from "../constants";
import _ from "lodash";


export default function useSocialSecutity(field: FieldType, section: SectionType, locale: string, value?: string) {

  const formik: FormikProps<any> = useFormikContext();
  const { highlightErrorFieldsState } = useContext(CommonDataContext);

  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const defaultLabel = _.get(field.config, `displayLabel.${locale}`, '');
  const [label, setLabel] = useState<string>(defaultLabel);
  const [ssnStringPattern, setSsnStringPattern] = useState<string>(SsnStringPatternsByCountryCodes.US);

  useEffect(() => {
    if (value) {
      checkAndSetIsInvalid(value);
    }
    return () => {
      formik.setFieldError(field.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    const nearestCountryField = section?.fields.find((f: FieldType) => checkIfIsCountryField(f.config.fieldName));
    if (!nearestCountryField) {
      return;
    }

    let nearestCountryFieldName = nearestCountryField.config.fieldName;
    const isComplexKeyUsed = _.includes(field.config.fieldName, ".");
    if (isComplexKeyUsed) {
      const prefix = field.config.fieldName.slice(0, field.config.fieldName.lastIndexOf(".") + 1);
      nearestCountryFieldName = `${prefix}${nearestCountryFieldName}`;
    }

    let ssnStringPattern;
    let label;
    switch (_.get(formik.values, nearestCountryFieldName)) {
      case 'CA':
        label = 'Social Insurance Number';
        ssnStringPattern = SsnStringPatternsByCountryCodes.CANADA
        break;
      default:
        label = defaultLabel;
        ssnStringPattern = SsnStringPatternsByCountryCodes.US;
    }

    setLabel(label);
    setSsnStringPattern(ssnStringPattern);
  }, [formik.values]);

  useEffect(() => {
    const formattedValue = formatValue(value);
    formik.setFieldValue(field.config.fieldName, formattedValue, false);
  }, [label, ssnStringPattern]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(field.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!field.config.displayed) {
      resetField();
    } else {
      let isEmpty = false;
      if (field.config.required) {
        isEmpty = _.isEmpty(value);
      }
      formik.setFieldError(
        field.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [field.config.displayed, field.config.required, isInvalid, value]);

  const resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, field.config.fieldName))) {
      //Remove Error
      formik.setFieldError(field.config.fieldName, '');
    }

    if (!_.has(formik.values, field.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(field.config.fieldName, '');
  };

  const formatValue = (value: string = '') => formatString(ssnStringPattern, value.replace(/[^\d]/g, '').slice(0, Constants.ssnLength));

  const checkAndSetIsInvalid = (value: string) => {
    const isRequiredAndEmpty = !!field.config.required && !value;
    const isInvalid = isRequiredAndEmpty || value?.length !== Constants.ssnLength;
    setIsInvalid(isInvalid);
  };

  const handleChange = useCallback((e) => {
    const formattedValue = formatValue(e.target.value);
    formik.setFieldValue(field.config.fieldName, formattedValue, false);
    checkAndSetIsInvalid(formattedValue);
  }, [ssnStringPattern]);

  return {
    label,
    isInvalid,
    handleChange,
  };
}