import formatString from 'format-string-by-pattern';
import { Constants } from '../constants';
import { checkIfCoApplicantSection, checkIfDynamicReferenceSection, checkIfIsOwnersSection } from '.';
import _ from 'lodash';


// OCA field name - credit app key hash map
const ambiguousFieldsToReplaceMap = {
  homeAddress: "address",
  homeCity: "city",
  homeState: "state",
  homePostalCode: "postalCode",
  percOwner: "ownerPercentage",
  employerPhone: "workPhone",
  driverLicenseNum: "driversLicenseNum",
  [`driverLicenseNum${Constants.maskedFieldNameSuffix}`]: `driversLicenseNum${Constants.maskedFieldNameSuffix}`,
  equipmentFinancing: "amountRequested",
  ccg_transaction_equipmentdescription: "equipmentDescription",
};


export const formatCreditAppToFormikFieldTypes = (creditApp: any, ocaConfig: any, isIndividualApp: boolean = false) => {

  const allContacts = _.orderBy([
    ...creditApp.creditApplicationOwner ?? [],
    ...creditApp.creditApplicationContacts ?? []
  ], "ownerNumber");

  const customValueByFieldName: Record<string, Function> = {
    bizOrIndividual: () => isIndividualApp ? 'individual' : 'business',
    isOwnerOrOfficer: () => creditApp.primaryContact.ownerPgId === allContacts[0]?.ownerPgId ? true : false,
    haveCoApplicant: () => isIndividualApp ? (allContacts.length === 2 ? 'yes' : 'no') : undefined,
  };

  const getFormattedValue = (creditApp: any, fieldType: string, fieldName: string) => {
    fieldName = _.includes(_.keys(ambiguousFieldsToReplaceMap), fieldName)
      ? _.get(ambiguousFieldsToReplaceMap, fieldName)
      : fieldName;
    const creditAppValue = _.get(creditApp, fieldName);
    if (fieldType === 'amount-field') {
      return creditAppValue ? _.toString(creditAppValue) : undefined;
    }
    if (fieldType === 'socialsecurity-field') {
      return creditAppValue ? formatString('999-99-9999', creditAppValue) : undefined;
    }
    if (fieldType === 'date-field') {
      return creditAppValue ? _.replace(creditAppValue, new RegExp('-', 'g'), '/') : undefined;
    }
    if (fieldType === 'taxid-field') {
      return creditAppValue ? formatString('99-9999999', creditAppValue) : undefined;
    }
    if (fieldType === 'percent-field') {
      return creditAppValue ? _.toString(creditAppValue) : undefined;
    }

    if (fieldType === Constants.maskedFieldType) {
      const value = _.get(creditApp, _.replace(fieldName, Constants.maskedFieldNameSuffix, ''));
      return value ? _.toString(value) : undefined;
    }
    /*     if (fieldName === 'applyingSelect') {
          const applyingSelectValue = creditApp.applyingSelect || creditApp.applyingFor;
          return applyingSelectValue ? applyingForMap.get(applyingSelectValue) : undefined;
        } */

    const booleanYesNoFieldNames = [
      'existingCustomer',
      'ssnExempt',
      'seasonalBusiness',
      'useByApplicant',
      'homeSameAsBiz',
      'agriculturalExempt',
    ];
    if (_.includes(booleanYesNoFieldNames, fieldName)) {
      if (creditAppValue === true) {
        return 'yes';
      }
      if (creditAppValue === false) {
        return 'no';
      }
    }

    const booleanTrueFalseFieldNames = [
      'providingGuaranty'
    ];
    if (_.includes(booleanTrueFalseFieldNames, fieldName)) {
      if (creditAppValue === true) {
        return 'true';
      }
      if (creditAppValue === false) {
        return 'false';
      }
    }

    return creditAppValue;
  };

  const allSections = _.flatMap(ocaConfig, 'sections');
  const result: any = {};

  _.forEach(allSections, section => {
    const isDynamicReferenceSection = checkIfDynamicReferenceSection(section);
    const isOwnersSection = checkIfIsOwnersSection(section) || checkIfCoApplicantSection(section);

    _.forEach(section.fields, field => {
      let keyToSet = field.config.fieldName;
      if (isDynamicReferenceSection) {
        const refIdx = _.parseInt(field.config.fieldName.match(/(\d)/)[0]);
        const refFieldKey = field.config.fieldName.slice(field.config.fieldName.lastIndexOf(".") + 1);
        const refObj = _.find(creditApp.references, { referenceIdx: refIdx });
        keyToSet = `references.${refIdx}.${refFieldKey}`;
        const valueToSet = getFormattedValue(refObj, field.type, refFieldKey);
        _.set(result, keyToSet, valueToSet);
        return;
      }
      if (isOwnersSection) {
        const ownerFieldKey = field.config.fieldName.slice(field.config.fieldName.lastIndexOf(".") + 1);
        _.forEach(allContacts, (owner, idx) => {
          keyToSet = `owners.${idx}.${ownerFieldKey}`;
          const valueToSet = getFormattedValue(owner, field.type, ownerFieldKey);
          _.set(result, keyToSet, valueToSet);
        });
        // format primary contact as well
        const primaryContactKeyToSet = `primaryContact.${ownerFieldKey}`;
        const valueToSet = getFormattedValue(creditApp.primaryContact, field.type, ownerFieldKey);
        _.set(result, primaryContactKeyToSet, valueToSet);
        return;
      }

      if (customValueByFieldName[keyToSet]) {
        const valueToSet = customValueByFieldName[keyToSet]();
        _.set(result, keyToSet, valueToSet);
        return;
      }

      const valueToSet = getFormattedValue(creditApp, field.type, keyToSet/* field.config.fieldName */);
      _.set(result, keyToSet, valueToSet);
    });
  });

  result.signature = creditApp.signature;

  // hotfix: set props which are not present in OCA form, but needed for correct owners processing
  const setOwnerNonFormikProps = (formiKeyPrefix: string, owner: any) => {
    _.set(result, `${formiKeyPrefix}.signature`, owner.signature);
    _.set(result, `${formiKeyPrefix}.signatureDate`, owner.signatureDate);
    _.set(result, `${formiKeyPrefix}.personalGuaranteeSignature`, owner.personalGuaranteeSignature);
    _.set(result, `${formiKeyPrefix}.personalGuaranteeSignatureDate`, owner.personalGuaranteeSignatureDate);
    _.set(result, `${formiKeyPrefix}.ownerPgId`, owner.ownerPgId);
    _.set(result, `${formiKeyPrefix}.ownerNumber`, owner.ownerNumber);
    _.set(result, `${formiKeyPrefix}.isOwnerPc`, owner.pc);
    _.set(result, `${formiKeyPrefix}.isOwnerPg`, owner.pg);
    _.set(result, `${formiKeyPrefix}.isOwnerPcOnly`, owner.pcOnly);
  };

  setOwnerNonFormikProps('primaryContact', creditApp.primaryContact);
  _.forEach(allContacts, (owner, idx) => {
    setOwnerNonFormikProps(`owners.${idx}`, owner);
  });

  console.log('result:');
  console.log(result);

  return result;

};

