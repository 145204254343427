import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EquipmentDataFromUrlParams } from '../types';
import { Constants } from '../../constants';

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: 'flex',
    margin: "16px 0",
    padding: 16,
    gap: 24,
    flexWrap: "wrap"
  },
  equipmentImage: {
    height: 224,
    width: 224,
    objectFit: "cover"
  },
  equipmentInfo: {
    display: 'flex',
    flexDirection: 'column',
    "& h2": {
      margin: "8px 0"
    }
  },
  detailsContainer: {
    display: "flex",
    marginTop: 8,
    height: "100%",
    columnGap: 64,
    flexWrap: "wrap"
  },
  detailsList: {
    listStyleType: "none",
    margin: 0,
    paddingLeft: 8,
    "& li": {
      marginBottom: 16
    }
  }
}));

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});


interface Props {
  equipmentData: EquipmentDataFromUrlParams
}

export const EquipmentViewOnly = ({ equipmentData }: Props) => {
  const classes = useStyles();
  const {
    year,
    make,
    city,
    model,
    state,
    newUsed,
    mileage,
    stockId,
    retailPrice,
    serialNumberVin,
    equipmentPictureUrl,
  } = equipmentData;

  return (
    <Card className={classes.cardContainer}>
      <img className={classes.equipmentImage} alt="equipment-image" src={equipmentPictureUrl} />
      <div className={classes.equipmentInfo}>
        <h3>{year} {make} {model}</h3>
        <div className={classes.detailsContainer}>
          <ul className={classes.detailsList}>
            {retailPrice && <li><b>Price:</b> {priceFormatter.format(parseFloat(retailPrice))}</li>}
            {year && <li><b>Year:</b> {year}</li>}
            {make && <li><b>Make:</b> {make}</li>}
            {model && <li><b>Model:</b> {model}</li>}
          </ul>
          <ul className={classes.detailsList}>
            {newUsed && <li><b>Condition:</b> {newUsed}</li>}
            {mileage && <li><b>Mileage:</b> {mileage}</li>}
            {stockId && <li><b>Stock Number:</b> {stockId}</li>}
            {serialNumberVin && <li><b>VIN:</b> {serialNumberVin.slice(0, Constants.equipmentVinLength)}</li>}
            {(state || (state && city)) && <li><b>Location:</b> {city ? city + ', ' + state : state}</li>}
          </ul>
        </div>
      </div>
    </Card>
  )
}